.wrap {
  padding: 0.186667rem 0;
  border-bottom: 1px solid #f3f3f3;
  background-color: #fff;
}

:global(.hairlines) .wrap {
  border-bottom: 0.5px solid #f3f3f3;
}

.logo {
  padding: 0 0.32rem;
}

.logo img {
  display: block;
  height: 0.666667rem;
}